.InfoLineContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.InfoLineItemEnd{
    margin-right: 75px;
    margin-left: 28px;
}
.InfoLineImg{
    width: 26px;
    height: 30px;
    margin-left: 20px;
    margin-right: 20px;
}
.InfoLineSpan{
    color: #000000;
    font-size: 17px;
    /* cursor: default;  */
    cursor: pointer;
    line-height: 25.5px;
}
.InfoLineLink{
    color: #000000;
    font-size: 19px; 
    line-height: 28.5px;
    font-weight: 600;
    padding: 15px 20px 15px 20px;
}

.InfoLineLeft{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 75px;
}
.InfoLineRight{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 75px; */
}

@media (max-width: 1200px) {

    .InfoLineContainer{
        height: 40px;
    }

    .InfoLineItemEnd{
        margin-right: 75px;
        margin-left: 20px;
    }
    .InfoLineImg{
        width: 18px;
        height: 18px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .InfoLineLeft{
        margin-left: 48px;
      }
    /* .InfoLineSpan{
        font-size: 13px;
        line-height: 19.5px;
    } */
    .InfoLineLink{
        /* font-size: 13px;
        line-height: 19.5px; */
        font-weight: 600;
        padding: 9px 17px 9px 17px;
    }
}

@media (max-width: 800px) {

    .InfoLineContainer{
        justify-content: space-between;
        height: 30px;
    }

    .InfoLineRight{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        /* margin-left: 75px; */
    }

    .InfoLineItemEnd{
        /* margin-right: 35px;
        margin-left: 10px; */
        margin-right: 0px;
        margin-left: 0px;
    }
    .InfoLineItemStart{
        /* margin-right: 35px; */
        /* margin-left: 20px;  */
        margin-left: 0px;
    }
    .InfoLineImg{
        width: 16px;
        height: 14px;
        margin-left: 7px;
        margin-right: 7px;
    }
    
    .InfoLineSpan{
        /* text-wrap: noWrap; */
        font-size: 12px;
        line-height: 14px;
    }
    .InfoLineLink{
        display: none;
    }
    .InfoLineLeft{
        display: none;
    }
}