.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #F3F3F3;
    height: 120px;
    position: fixed;
    width: 100%;
    z-index: 12;
    position: fixed;
    transition-duration: .25s;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
  }
  .RightPartHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-right: 75px;
  }
  .Logo{
    width: 284px;
    height: 44px;
    margin-left: 75px;
  }
  .HeaderButton{
    background-color: transparent;
    border: none;
    color: #0C1A3F;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    margin-left: 37px;
    padding: 0;
}
/* .BurgerButton{
    display: none;
    background-color: transparent;
    border: none;
} */
.BurgerImg{
  width: 38px;
  height: 38px;
  margin: 0px;
  padding: 0px;
}


.hamburger-menu{
  display: none;
  z-index: 100;
}

#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}
.menu__btn {
  display: none;
  position: relative;
  /* top: 50px; */
  /* right: 20px; */
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  /* width: 300px; */
  /* height: 100%; */
  margin: 0;
  padding: 60px 0 0 0 ;
  list-style: none;
  background-color: #ECEFF1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
  transition-duration: .25s;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #0C1A3F;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
}
.menu__item:hover {
  background-color: #CFD8DC;
}


@media (max-width: 1500px) {
  .menu__btn {
    font-size: 16px;
  }
}
@media (max-width: 1200px) {
  .Header {
    height: 64px;
  }
  .Logo{
    width: 273.16px;
    height: 42.34px;
    margin-left: 48px;
  }
  .HeaderButton{
    display: none;
  }
  .menu__btn{
    display: block;
  }
  
.hamburger-menu{
  display: block;
}
}
@media (max-width: 800px) {
  
  .Header {
    height: 39px;
  }
  .Logo{
    width: 144px;
    height: 22px;
    margin-left: 18px;
  }
  .RightPartHeader{
    margin-right: 35px;
  }
  .HeaderButton{
    display: none;
  }
  .menu__btn{
    display: block;
    width: 22px;
    height: 22px
  }
  .BurgerImg{
    width: 22px;
    height: 22px;
    margin: 0px;
    padding: 0px;
  }
  .menu__btn > span::before {
    content: '';
    top: -6px;
  }
  .menu__btn > span::after {
    content: '';
    top: 6px;
  }
  .menu__box {
    padding: 30px 0 0 0 ;

  }

}
  