.Modal{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 100;
}
.activeModal{
    opacity: 1;
    pointer-events: all;

}
.ModalMinContainer{
    display: flex; 
    align-items: flex-start;
    justify-content: flex-start; 
    padding: 0; 
    border-radius: 34.08px;
    background-color: rgba(196, 215, 239, 1);
    min-height: 10rem;
    max-width: 40rem;
    overflow: hidden;
    overflow-y: auto;
    transition: 0.5s all;
    z-index: 100;
}
.ModalMinContainerDoc{
    display: flex; 
    align-items: flex-start;
    justify-content: flex-start; 
    padding: 0; 
  
    background-color: rgba(196, 215, 239, 1);
    /* height: 20rem; */

    /* min-height: 10rem;
    max-width: 40rem; */
    
    overflow: hidden;
    overflow-y: auto;
    transition: 0.5s all;
    z-index: 100;
}
.DocImgDoc{
    width: 50rem;
    height: 40rem;
}

.ContentModal{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}
.modalPreHeader{
    width: 100%;
    height: 40px;
}
/* .preHeaderModalElement{
    background-color: rgba(205, 129, 13, 1);
    width: 197.2px;
    height: 30px;
    border-radius: 0 26.82px 0 10.85px;
} */

.preHeaderModalElement{
    background-color: rgba(205, 129, 13, 1);
    width: 60px ;
    height: 40px;
    border-radius: 0 26.82px 0 10.85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
}

.activeModalContent{
    z-index: 100;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 40px 40px 40px;
    width: 100%;
    height: 100%;
}

.modalContainerDoc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.modalHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 14px;
}
.modalTittle{
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: rgba(36, 42, 64, 1);
}
.modalDescription{
    font-size: 19.44px;
    font-weight: 400;
    line-height: 29.16px;
    color: rgba(36, 42, 64, 1);
}
.butonMotalMargin{
    margin-top: 26px;
}
.fw{
    font-weight: 400;
}
.docHeader{
    width: 100%;
    height: 2rem;
    background-color: rgb(50, 54, 57);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.CloseButton2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #fff;
    width: 2rem;
    height: 2rem;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;

}


@media (max-width: 800px) {
    .ModalMinContainer{
        border-radius: 24px;
        min-height: 10rem;
        max-width: 90%;
    }
    .modalContainer{
        padding: 0 20px 20px 20px;
    }
    .modalPreHeader{
        height: 30px;
    }
    .modalDescription{
        font-size: 16px;
        line-height: 18px;
    }
    .modalTittle{
        font-size: 24px;
        line-height: 34px;
    }
    /* .preHeaderModalElement{
        width: 150px;
        height: 20px;
        border-radius: 0 24px 0 8px;
    } */

    .preHeaderModalElement{
        width: 50px ;
        height: 30px;
        border-radius: 0 24px 0 8px;
    }

    .butonMotalMargin{
        margin-top: 16px;
    }
    .DocImgDoc{
        width: 22rem;
        height: 30rem;
    }
}
