.Fl-row-c-c{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Fl-row-e-c{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.Fl-row-c-e{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.Fl-row-e-e{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.Fl-row-s-s{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.Fl-row-e-s{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}
.Fl-row-s-e{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.Fl-col-c-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Fl-col-e-c{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.Fl-col-c-e{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.Fl-col-e-e{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.Fl-col-s-s{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.Fl-col-e-s{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.Fl-col-s-e{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.PrimaryButton{
    border-radius: 54px;
    background-color: #CD810D;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    color: #FFFFFF;
    text-wrap: nowrap;
    border: 3px solid #CD810D;
    padding: 20px 60px 20px 60px;
    text-decoration: none;

    box-shadow: 0 0 0 #CD810D;
    animation: pulse 2s infinite;
}
.PrimaryButton:hover{
    background-color: #FFFFFF;
    color: #CD810D;
    animation: none;
}

.SecondaryButton{
    border-radius: 54px;
    background-color: rgba(36, 42, 64, 1);
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    color: #FFFFFF;
    text-wrap: nowrap;
    border: 3px solid rgba(36, 42, 64, 1);
    padding: 24.5px 60px 24.5px 60px;
    text-decoration: none;

    box-shadow: 0 0 0 rgba(36, 42, 64, 1);
    animation: pulse 2s infinite;
}
.SecondaryButton:hover{
    background-color: #FFFFFF;
    color: rgba(36, 42, 64, 1);
    animation: none;
}

.imgMainContainer{ 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 100vh;
    background-image: 
    linear-gradient(
        #242A40D6 100%,
        #21273C6B 42%,
        #1E243996 58.8%,
        #1C223581 50.4%, 
        #181E3156 33.6%, 
        #0F152500 0%
        ),
        url("./ImageSource//Back.png");
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-color: #0C1A3F;
        background-size: cover;
}
.partImg2{
    margin-top: 6px;
    width: 14px;
    height: 100%;
}

.pulse {
    display: block;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 2s infinite;
  }
  
  .pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
   0% {
      -webkit-box-shadow: 0 0 0 0 rgba(205, 129, 13, 0.4);
    }
   70% {
     -webkit-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
   }
   100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(205, 129, 13, 0.4);
     box-shadow: 0 0 0 0 rgba(205, 129, 13, 0.4);
    }
   70% {
     -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
      box-shadow: 0 0 0 15px rgba(204,169,44, 0);
   }
   100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
     box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
@media (max-width: 1200px) {
    .PrimaryButton{
        border-radius: 50px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 700;
        padding: 15px 40px 15px 40px;
        
    }
    .SecondaryButton{
        border-radius: 50px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 700;
        padding: 15px 40px 15px 40px;
    }
    .partImg2{
        margin-top: 4px;
    }
}
@media (max-width: 800px) {
    .partImg2{
        margin-top: 3px;
        width: 10px;
        height: 100%;
    }
}