.FigCaruselBody{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.caruselChild{
    position: absolute;
    width: 200px;
    right: -100px;
    bottom: 0;
    z-index: 100;
}

.FigCaruselСontainer{
    display: flex;
    flex-direction: row;
    width: 642px;
    height: 564px;
}
 .FigcaruselImgcontainer{
    width: 642px;
    height: 564px;
    aspect-ratio: 3/2;
    clip-path: polygon(0 100%,calc(100% - 170px ) 100%,100% 0,0 0);
 }
 .FigCaruselСontainer > .carousel > .carousel-control-next{
    right: 100px;
 }
 .FigCaruselСontainer > .carousel > .carousel-indicators{
    display: none;
 }
 .FigCaruselСontainer > .carousel > .carousel-caption{
    display: none;
 }
 .FigCarDescription {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    background-color: rgba(218, 231, 247, 0.88);
    width: 598px;
    height: 126px;
    aspect-ratio: 3/2;
    clip-path: polygon(0 0,calc(100% - 50px) 0,100% 100%,0 100%);
}
.FigCarDescriptionText{
    width: 100%;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: rgba(36, 42, 64, 1);
    text-wrap: wrap;
    padding: 35px 100px 35px 30px;
}
.FigcaruselImgcontainer img{
  position: relative;
}

.indOff > .carousel > .carousel-control-next{
    display: none;
}

.indOff > .carousel > .carousel-control-prev{
    display: none;
}

 @media (max-width: 1200px) {
    .FigCaruselСontainer{
        width: 453px;
        height: 364px;
    }
    .FigcaruselImgcontainer{
        width: 453px;
        height: 364px;
        aspect-ratio: 3/2;
        clip-path: polygon(0 100%,calc(100% - 170px ) 100%,100% 0,0 0);
     }
    .FigcaruselImgcontainer{
        clip-path: polygon(0 100%,calc(100% - 110px ) 100%,100% 0,0 0);
    }
    .FigcaruselImgcontainer img{
        position: relative;
    }
    .FigCarDescription {
        width: 448px;
        height: 82px;
    }
    .FigCarDescriptionText{
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        padding: 20px 80px 20px 20px;
    }
    .FigCaruselСontainer > .carousel > .carousel-control-next{
        right: 60px;
     }
 }
 @media (min-width: 1200px) {
  
 }
 @media (max-width: 800px) {
    
    .FigCaruselСontainer{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .FigcaruselImgcontainer{
        width: 100%;
        height: 100%;
        clip-path: polygon(0 100%,calc(100% ) 100%,100% 0,0 0);
     }
     .custom_full_width {
        width: 100vw;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
      }
      .FigCarDescription{
        display: none;
      }
      .FigCaruselСontainer > .carousel > .carousel-control-next{
        right: 0px;
     }
     .childOption{
        display: none;
     }
 }

