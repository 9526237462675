.GreetingComp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 265px;
}
.FSpnGrComp{
    font-size: 44px;
    font-weight: 600;
    line-height: 48px;
    text-wrap: wrap;
    color: rgba(255, 255, 255, 1);
}
.SSpnGrComp{
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-wrap: wrap;
    color: rgba(255, 255, 255, 1);
    padding-left: 96px;
}

@media (max-width: 1500px) {

}
@media (max-width: 1200px) {
    .GreetingComp{
        flex-direction: column;
        align-items: flex-start;
        margin-top: 140px;
    }
    .FSpnGrComp{
        font-size: 34px;
        line-height: 35px;
    }
    .SSpnGrComp{
        padding-left: 0px;
        width: 70%;
    }
}
@media (max-width: 800px) {
    .GreetingComp{
        flex-direction: column;
        align-items: flex-start;
        margin-top: 109px;
    }
    .FSpnGrComp{
        font-size: 28px;
        line-height: 29px;
    }
    .SSpnGrComp{
        /* margin-top: 33px; */
        font-size: 20px;
        line-height: 22px;
        padding-left: 0px;
        width: 100%;
    }
}