.MoDescTitle{
    width: 100%;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

@media (max-width: 1500px) {


}

@media (max-width: 1200px) {
    .MoDescTitle{
        margin-bottom: 22px;
        /* margin-top: 58px; */
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        /* margin-left: 1rem; */
    }
}
@media (max-width: 800px) {
    .MoDescTitle{
        margin-bottom: 22px;
        /* margin-top: 58px; */
        font-size: 18px;
        line-height: 24px;
        /* margin-left: 1rem; */
    }
}