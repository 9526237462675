.Service{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 173px;
    box-shadow: 4.62px 4.62px 23.08px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-decoration: inherit;

}
.Service:hover{
    box-shadow: 4.62px 4.62px 23.08px 0px rgba(0, 0, 0, 0.15);

}
.LeftPartService{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: #C4D7F0;
    /* border-radius: 20px 0 0 20px; */
    border-radius: 20px;
  
}
.RightPartService{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 100%;
    background-color: #DAE7F7;
    /* border-radius: 0 20px 20px 0; */
    border-radius: 20px ;
}
.ServieTitile{
    color: #242A40;
    font-size: 34px;
    font-weight: 600;
    line-height: 23px;
    text-wrap: wrap;
    margin: 0 34px 0 34px;
    /* cursor: default; */
}
.ServieDescription{
    color: #242A40;
    font-size: 22px;
    font-weight: 500;
    line-height: 23px;
    text-wrap: wrap;
    margin: 24px 34px 0 34px;
    /* cursor: default; */
}
.ControlArrow{
    
}

@media (max-width: 1200px) {
    .Service{
        height: 105px;
    }
    .LeftPartService{
        /* border-radius: 11px 0 0 11px; */
        border-radius: 11px;
    }
    .RightPartService{
        width: 60px;
        border-radius: 0 11px 11px 0;
    }
    .ServieTitile{
        font-size: 19px;
        line-height: 13.94px;
        margin: 0 22px 0 22px;
    }
    .ServieDescription{
        font-size: 16px;
        line-height: 16px;
        margin: 8px 22px 0 22px;
    }
}
@media (max-width: 800px) {
    .Service{
        height: 105px;
        width: 100%;
    }
    .LeftPartService{
        /* border-radius: 11px 0 0 11px; */
        border-radius: 11px;
    }
    .RightPartService{
        width: 60px;
        border-radius: 0 11px 11px 0;
    }
    .ServieTitile{
        font-size: 19px;
        line-height: 13.94px;
        margin: 0 21px 0 21px;
    }
    .ServieDescription{
        font-size: 16px;
        line-height: 18px;
        margin: 8px 21px 0 21px;
    }
}