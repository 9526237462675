.ResearchImg{
    margin-top: 104px;
    width: 100%;
    height: 100%;

}
@media (max-width: 1200px) {
    .ResearchImg{
        margin-top: 60px;
    }
    .custom_full_widthRes {
        width: 100vw;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
      }
}

@media (max-width: 800px) {
    .ResearchImg{
        margin-top: 40px;
    }

}