.MainPage{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: rgba(243, 243, 243, 1);
}
.MainBody{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}
.MainContainer{ 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 100vh;
    background-image: 
    linear-gradient(
        #242A40D6 100%,
        #21273C6B 42%,
        #1E243996 58.8%,
        #1C223581 50.4%, 
        #181E3156 33.6%, 
        #0F152500 0%
        ), 
        url("../ImageSource//Back.png");
        background-repeat: no-repeat;
        background-color: #0C1A3F;
        background-size: cover;
}
.GreeteingContainer{
    display: flex;
    margin-top: 296px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    width: 100%;
}
.GreetingText1{
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 600;
    line-height: 55px;
    width: 468px;
    text-wrap: wrap;
    cursor: default;
}
.GreetingText2{
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    width: 468px;
    text-wrap: wrap;
    word-break: break-word;
    margin-top: 27px;
    cursor: default;
}
.GreetingText3{
    color: #FFFFFF;
    font-size: 38px;
    font-weight: 500;
    text-wrap: wrap;
    cursor: default;
    Line-height: 38px;
    width: 50%;
}
.Sp{
    margin-top: 76px;
}
.ServicesContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 163px;
    width: 100%;
}
.ArrowImg{
    width: 241px;
    height: 194px;
    margin-right: 127px;
    margin-left: 127px;
}
.GridContainer{
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 28px));
  gap: 56px;
  width: 100%;
  margin-bottom: 102px;
  margin-top: 54px;
}
.SecondContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
}
.SecondPanel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 552px; */
    height: 100%;
    border-radius: 36px;
    background-image: linear-gradient( rgba(221, 229, 237, 0.9), rgba(221, 229, 237, 0.9)), url("../ImageSource/Back.png");
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    margin-top: 125px;
}

.SecondPanelContaoner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    /* height: 80%; */
}
.GreetingText6{
    color: rgba(36, 42, 64, 1);
    font-size: 38px;
    font-weight: 500;
    text-wrap: wrap;
    cursor: default;
    Line-height: 38px;
    margin-bottom: 57px;
    margin-top: 57px;
}
.SecondGridContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 46px;
    width: 100%;
    height: 100%;
    margin-bottom: 57px;
}
.GridElem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 140px; */
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    box-shadow: 3.66px 3.66px 18.32px 0px rgba(0, 0, 0, 0.15);
}
.GridElemContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.GridElemText{
    font-size: 18px;
    font-weight: 600;
    line-height: 18.32px;
    text-wrap: wrap;
    cursor: default;
    padding-left: 12px;
    /* width: 80%; */
}
.ThirdPanel{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 125px;
    margin-bottom: 121px;
}
.ThirdPanelLeftPart{
    max-width: 50%;
    height: 100%;
}
.ThirdPanelLeftPart img {
    width: 100%;
    height: auto;
}
.ThirdPanelRightPart{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(50% - 107px);
    height: 90%;
    margin-left: 107px;
}
.GreetingText5{
    color: #242A40;
    font-size: 22px;
    font-weight: 500;
    line-height: 23px;
    text-wrap: wrap;
    cursor: default;
}
.GreetingText4{
    color: rgba(36, 42, 64, 1);
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    text-wrap: wrap;
    cursor: default;
    margin-top: 57px;
}
.Mm{
    margin-top: 26px;
}
.DocsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 710px; */
    background-color: rgba(36, 42, 64, 1);
}
.SecondContainerItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 70%;
}
.DocsTittleText{
    color: rgba(255, 255, 255, 1);
    font-size: 38px;
    font-weight: 500;
    line-height: 32px;
    cursor: default;
    margin: 80px 0 80px 0;
}
.DocsArea{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    width: 100%;
    margin-bottom: 100px;
    /* align-items: center;
    align-self: center; */


    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(1, 1fr); */
    gap: 46px;
    /* justify-content: center; */
    /* grid-template-areas:
    "top top top"
    ". center ."
    ". center ."
    ". center ."; */
}

.horizontal-1 { grid-area: 1 / 1 / 2 / 2; }
.horizontal-2 { grid-area: 1 / 2 / 2 / 3; }
.horizontal-3 { grid-area: 1 / 3 / 2 / 4; }

/* Вертикальный элемент (ножка буквы Т) */
.vertical {
  grid-area: 2 / 2 / 4 / 3; /* Занимает 2-3 строки во 2-й колонке */
}
.GridImage{
    width: 60px;
    height: 60px;
}
.gridElCenter{
    /* justify-self: center;
    align-items: center;
    align-self: center; */
    grid-area: center;
}

.GreetingText7{
    color: rgba(36, 42, 64, 1);
    font-size: 38px;
    font-weight: 500;
    text-wrap: wrap;
    cursor: default;
    Line-height: 34px;
    margin-bottom: 20px;
}

.DocImg{
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    cursor: pointer;
    width: 100%;
}

.ThirdPanelLeftPartMob{
    display: none;
}

.customn{
    object-fit: cover;
}

@media (max-width: 1500px) {
    .GreetingText5 {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
    }
}

@media (max-width: 1200px) {
    .MainBody{
        width: calc(100% - 140px);
        margin-left: 70px;
        margin-right: 70px;
    }
    .GreeteingContainer{
        margin-top: 139px;
    }
    .GreetingText1{
        font-size: 34px;
        line-height: 35px;
    }
    .GreetingText2{
        width: 100%;
        font-size: 28px;
        line-height: 29px;
        margin-top: 19px;
    }
    .GreetingText3{
        font-size: 34px;
        Line-height: 35px;
        width: 100%;
    }
    .ArrowImg{
        width: 213.33px;
        height: 171.73px;
        margin-right: 80px;
        margin-left: 80px;
    }
    .Sp{
        margin-top: 38px;
    }
    .ServicesContainer{
        margin-top: 57px;
    }
    .GridContainer{
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 16px));
        gap: 34px;
        width: 100%;
        margin-bottom: 60px;
        margin-top: 45px;
    }
    .SecondPanel{
        border-radius: 30px;
        margin-top: 50px;
    }
    .SecondContainer{
        width: calc(100% - 140px);
        margin-left: 70px;
        margin-right: 70px;
    }
    .GreetingText6{
        font-size: 31px;
        Line-height: 30.96px;
        margin-bottom: 37px;
        margin-top: 37px;
    }
    .SecondGridContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        width: 100%;
    }
    .GridElem{
        /* height: 169px; */
        height: 100%;
        border-radius: 15px;
    }
    .GridElemText{   
        font-size: 16px;
        line-height: 17px;
    }
    .ThirdPanel{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 93px;
        margin-bottom: 93px;
    }
    .ThirdPanelLeftPart{
        width: 100%;
        height: auto;
        margin-bottom: 35px;
    }
    .ThirdPanelRightPart{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: calc(50% - 57px);
        margin-left: 57px;
    }
    .GreetingText7{
        font-size: 28px;
        Line-height: 20px;
    }

    .GreetingText7{
        font-size: 34px;
        Line-height: 20px;
    }
    .GreetingText5{
        font-size: 16px;
        line-height: 16px;
    }
    .GreetingText4{
        font-size: 23px;
        line-height: 23px;
        margin-top: 59px;
    }
    .Mm{
        margin-top: 46px;
    }

    .SecondContainerItem{
        width: calc(100% - 140px);
        margin-left: 70px;
        margin-right: 70px;
    }
    .DocsTittleText{
        margin: 44px 0 44px 0;
        font-size: 34px;
        line-height: 34.13px;
    }
    .DocsArea{
        margin-bottom: 56px;
    }
    .DocImg{
        /* width: 100%; */
        height: auto;
    }
}
@media (max-width: 800px) {
    .MainBody{
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }
    .GreeteingContainer{
        margin-top: 110px;
    }
    .GreetingText1{
        font-size: 28px;
        line-height: 29px;
    }
    .GreetingText2{
        width: 100%;
        font-size: 23px;
        line-height: 24px;
        margin-top: 15px;
    }
    .GreetingText3{ 
        font-size: 28px;
        Line-height: 29px;
        width: 100%;
    }
    .ArrowImg{
        position: relative;
        width: 116px;
        height: 93.38px;
        margin-right: 0px;
        margin-left: 0px;
        top: 60px;
        right: 100px;
    }
    .Sp{
        margin-top: 38px;
    }
    .ServicesContainer{
        margin-top: 57px;
    }
    .GridContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 30px;
        margin-bottom: 70px;
        margin-top: 61px;
    }
    .SecondPanel{
        border-radius: 12.35px;
        margin-top: 30px;
    }
    .SecondContainer{
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }
    .GreetingText6{
        font-size: 28px;
        Line-height: 29px;
        margin-top: 37px;
        margin-bottom: 37px;
    }

    .SecondGridContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        margin-bottom: 37px;
    }
    .GridElem{
        border-radius: 15px;
    }
    .GridImage{
        display: none;
    }
    .GridElemText{   
        font-size: 16px;
        line-height: 17px;
        padding-left: 0;
    }
    .ThirdPanel{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 31px;
        margin-bottom: 41px;
    }
    .ThirdPanelLeftPart{
        display: none;
    }

    .ThirdPanelLeftPartMob{
        margin-top: 31px;
        max-width: 100%;
        width: 100%;
        height: 380px;
        display: inline-block; 
        overflow: hidden;
    }
    .ThirdPanelLeftPartMob img {
        display: block;
        width: 100%;
        height: auto;
        transform: scale(1.1);
    }

    .ThirdPanelRightPart{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-left: 0;
    }
    .GreetingText7{
        font-size: 28px;
        Line-height: 20px;
    }
    .GreetingText5{
        font-size: 16px;
        line-height: 16px;
    }
    .GreetingText4{
        font-size: 23px;
        line-height: 23px;
        margin-top: 61px;
    }
    .Mm{
        margin-top: 30px;
    }

    .SecondContainerItem{
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }
    .DocsTittleText{
        margin: 22px 0 22px 0;
        font-size: 28px;
        line-height: 29px;
    }
    .DocsArea{
        margin-bottom: 22px;
    }
    .DocImg{
        /* width: 100%; */
        height: auto;
    }
    .GridElemContainer{
        padding:20px;
    }
}
