.Caruselcontainer{
    width: 100%;
    height: 100%;
}
/* @media (max-width: 1200px) {
    .Caruselcontainer{
        height: 400px;
    }  
}

@media (max-width: 800px) {
    .Caruselcontainer{
        height: 235px;
    }  
} */
 .caruselImgcontainer{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
 }
 .caruselImgcontainer img{
  position: relative;
  /* transform: translate(0, -20%); */
 }

 @media (max-width: 1200px) {
    .caruselImgcontainer img{
        position: relative;
        transform: translate(0, 0%);
       }
 }
 @media (min-width: 1200px) {
    .caruselImgcontainer img{
        position: relative;
        transform: translate(0, -10%);
    }
 }
