.Footer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.FoterContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.FirstFooterRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    margin: 100px 0px 100px 0;
}
.FoterColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* height: 250px; */
    height: 100%;
}
.FooterText1{
    color: #242A40;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    text-wrap: wrap;
    margin-top: 31px;
    padding-left: 12px;
    cursor: default;
}
.FooterText2{
    color: rgba(184, 184, 184, 1);
    font-size: 36px;
    font-weight: 500;
    line-height: 36px;
    text-wrap: wrap;
    cursor: default;
}
.FooterText4{
    color: rgba(36, 42, 64, 1);
    font-size: 31px;
    font-weight: 600;
    line-height: 36px;
    text-wrap: wrap;
    cursor: pointer;
    margin-top: 10px;
    /* cursor: default; */
}
.FooterText5{
    color: rgba(184, 184, 184, 1);
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    text-wrap: wrap;
    cursor: pointer;
    margin-bottom: 12px;
}

.FooterText6{
    color: rgba(36, 42, 64, 1);
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    text-wrap: wrap;
    /* cursor: default; */
    margin-top: 25px;
    padding-left: 12px;
}
.FooterText7{
    color: rgba(36, 42, 64, 1);
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    text-wrap: wrap;
    /* cursor: default; */
    margin-top: 31px;
    padding-left: 12px;
}
.FooterLogo{
    width: 284px;
    height: 44px;
}
.SecondFooterRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
    margin-bottom: 100px;
}


@media (max-width: 1200px) {
    .FoterContainer{
        width: calc(100% - 140px);
        margin-left: 70px;
        margin-right: 70px;
    }
    .FirstFooterRow{
        width: 100%;
        margin: 68px 0px 68px 0;
    }
    .FoterColumn{
        height: 100%;
    }
    .FooterLogo{
        width: 266px;
        height: 38px;
    }
    .FooterText6{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 18px;
    }
    .FooterText7{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 15px;
    }
    .FooterText1{
        font-size: 16px;
        line-height: 16px;
        margin-top: 15px;
    }
    .FooterText2{
        font-size: 19px;
        line-height: 19px;
        margin-top: 18px;
        padding-left: 12px;
    }
    .FooterText4{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 10px;
        padding-left: 12px;
    }
    .SecondFooterRow{
        width: 100%;
        margin-bottom: 56px;
    }
    .FooterText5{
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 12px;
        padding-left: 12px;
    }
}

@media (max-width: 800px) {
    .FoterContainer{
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }
    .FirstFooterRow{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin: 42px 0px 18px 0;
    }
    .FooterLogo{
        width: 266px;
        height: 38px;
    }
    /* .GreetingText6{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 18px;
    } */
    .GreetingText7{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 15px;
    }
    .FooterText1{
        font-size: 16px;
        line-height: 16px;
        margin-top: 15px;
    }
    .FooterText2{
        font-size: 19px;
        line-height: 19px;
        margin-top: 17px;
        padding-left: 12px;
    }
    .FooterText4{
        font-size: 16px;
        line-height: 19.2px;
        margin-top: 6px;
        padding-left: 12px;
    }
    .SecondFooterRow{
        margin-bottom: 56px;
    }
    .FooterText5{
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 12px;
        padding-left: 12px;
    }
}